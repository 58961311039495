import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { colors } from '@lib/components/bedrock/fundations';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { grey6 } from '../lib/components/bedrock/SwColors';

const HeroContainer = styled('div')`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const Subtitle = styled(SwTypography)`
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Paper = styled('div')`
    z-index: 1;
    display: flex;
    background: white;
    align-items: start;
    flex-direction: column;
    max-width: 450px;
    height: fit-content;
    border: 1px solid ${colors.border};
    border-radius: 8px;
    padding: ${({ theme }) => theme.spacing(3)};
    gap: ${({ theme }) => theme.spacing(2)};
    margin-block-start: ${({ theme }) => theme.spacing(4)};
    inline-size: 50%;
    gap: 24px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        inline-size: 100%;
    }
`;

const Container = styled('div')`
    aspect-ratio: 85/41;
    max-height: 574px;
    width: 100%;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        max-height: auto;
        aspect-ratio: 3/4;
    }
`;

const IndustryTemplate = ({ data, path }) => {
    const { sections, title, subtitle, hero } = data.page;
    const { t } = useTranslation();

    const parentSlug = path.split('/').at(1) as 'finance' | 'business';

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.solutions.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwLayout background={grey6}>
                <Container>
                    <HeroContainer>
                        <GatsbyImage
                            alt={hero.alt}
                            image={hero.gatsbyImageData}
                            style={{ height: '100%', width: '100%' }}
                        />
                    </HeroContainer>
                    <SwContainer>
                        <Paper>
                            <SwFlexBox flexDirection={'column'} gap={12}>
                                <SwTypography
                                    bold={true}
                                    color={colors.text.secondary}
                                    component={'p'}
                                    uppercase={true}
                                    variant={'caption'}
                                >
                                    {t(`industry_page.${parentSlug}`)}
                                </SwTypography>
                                <SwTypography component={'h1'} variant={'h3'}>
                                    {title}
                                </SwTypography>
                                <Subtitle color={colors.text.secondary} variant={'body1'}>
                                    {subtitle}
                                </Subtitle>
                            </SwFlexBox>
                            <SwInternalLink size={'large'} to={WEBSITE_MENU_ITEM.getInTouch.link}>
                                {t('get_in_touch')}
                            </SwInternalLink>
                        </Paper>
                    </SwContainer>
                </Container>
                <WebsiteSectionsLayout>
                    <SwContainer paddingBlock={'32px 0'}>
                        <SwCmsRenderer content={sections.at(0)} />
                        <SwLine spacing={4} />
                        <SwCmsRenderer content={sections.at(1)} />
                        <SwLine spacing={4} />
                        <SwCmsRenderer content={sections.at(2)} />
                    </SwContainer>
                    <SwContainer>
                        <SwCmsRenderer content={sections.at(3)} />
                    </SwContainer>
                </WebsiteSectionsLayout>
                <SwWebsiteFooter newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getIndustryPage($id: String, $locale: GraphCMS_Locale = en, $originalPath: String) {
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        enPage: graphCmsPage(slug: { eq: $originalPath }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            slug
            title
            subtitle
            seo {
                ...Essentials_GraphCMS_Seo
            }
            hero {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            sections {
                ... on GraphCMS_Section {
                    ...Essentials_GraphCMS_Section
                    children {
                        ...Essentials_GraphCMS_Divider
                        ...Essentials_GraphCMS_EmphasizedText
                        ... on GraphCMS_Grid {
                            ...Essentials_GraphCMS_Grid
                            children {
                                ... on GraphCMS_GridItem {
                                    ...Essentials_GraphCMS_GridItem
                                    children {
                                        ...Essentials_GraphCMS_FeatureVideoCard
                                        ...Essentials_GraphCMS_LogoCard
                                        ...Essentials_GraphCMS_CustomersCarousel
                                        ...Essentials_GraphCMS_CallToAction
                                        ...Essentials_GraphCMS_FeatureCard
                                        ...Essentials_GraphCMS_Testimonial
                                        ...Essentials_GraphCMS_AccordionSectionsList
                                        ...Essentials_GraphCMS_SolutionCard
                                        ...Essentials_GraphCMS_ReferenceCard
                                        ...Essentials_GraphCMS_Card
                                    }
                                }
                            }
                        }
                        ...Essentials_GraphCMS_MediaCard
                    }
                }
            }
        }
    }
`;

export default IndustryTemplate;
